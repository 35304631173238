.container {
    width: 50%;
    min-height: 100vh;
    position: relative;

    @media (max-width: 1100px) {
        width: 100%;
        min-height: fit-content;
    }

    .container_header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-right: 25px;

        h1{
            @media (max-width: 500px) {
                font-size: 25px;
            }
        }

        .update {
            width: 40px;
            height: 40px;
            cursor: pointer;
            padding: 10px;
            border-radius: 100%;
            background-color: #ffa600aa;
            transition-duration: 300ms;
            -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);

            &:active {
                transform: rotate(180deg);
                transition-duration: 300ms;
            }
        }
    }

    .statics_container {
        margin-top: 15px;
        display: grid;
        row-gap: 15px;
        column-gap: 25px;
        width: fit-content;
        grid-template-columns: 1fr 1fr 1fr;

        @media (max-width: 500px) {
            column-gap: 10px;
        }
    }

    .items_container {
        margin-top: 25px;

        h2 {
            color: gray;

            @media (max-width: 500px) {
                font-size: 20px;
            }
        }

        .header {
            width: unset;
            align-items: center;
            display: flex;
            justify-content: space-between;
            position: relative;

            &::before {
                bottom: -10px;
                position: absolute;
                content: '';
                height: 1px;
                background-color: #e6e6e6;
                width: 100%;
            }

            .count {
                font-size: 20px;
                font-weight: 600;
            }
        }

        .items {
            margin-left: auto;
            margin-right: auto;
            width: fit-content;
            margin-top: 25px;
            display: flex;
            flex-direction: column;
            gap: 25px;
        }
    }
}