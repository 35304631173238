.container {
    max-width: 600px;
    height: fit-content;
    background-color: white;
    border-radius: 16px;
    -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 15px 25px;

    .icon {
        color: #ffa600;
        width: 40px;
        height: 40px;
    }

    .info {
        margin-top: 10px;
        text-align: center;

        .title {
            font-weight: 600;
            margin-bottom: 5px;
        }

        .desc {
            font-size: 13px;
            color: gray;
        }

        button {
            background-color: lightgray;
            height: fit-content;
            border-radius: 15px;
            font-weight: 400;
            transition-duration: 300ms;
            padding: 5px 10px;
            margin-top: 10px;
            cursor: pointer;
            -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);


            &:hover {
                background-color: #FFA500 !important;
            }
        }
    }
}