.container {
    display: flex;
    width: 100%;
    height: 100vh;
    flex-direction: column;
    justify-content: center;


    .form {
        width: fit-content;
        padding: 25px;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        margin-right: auto;
        margin-left: auto;
        -webkit-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        -moz-box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);

        .logo {
            width: 200px;
            height: 50px;
            background-color: black;
        }

        .inputs_container {
            margin-top: 50px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .input_container {
                display: flex;
                border: 2px solid lightgrey;
                gap: 10px;
                padding: 10px;
                border-radius: 16px;
                align-items: center;
                transition-duration: 300ms;

                input {
                    font-size: 17px;
                }

                &:hover {
                    border-radius: 8px;
                    border-color: #FFA500;
                }
            }
        }


        button {
            margin-top: 25px;
            background-color: lightgray;
            width: 170px;
            padding: 5px;
            height: fit-content;
            cursor: pointer;
            font-size: 17px;
            border-radius: 16px;
            transition-duration: 300ms;
            color: white !important;
            border-color: transparent !important;
            -webkit-box-shadow: 4px 3px 8px -3px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 3px 8px -3px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 3px 8px -3px rgba(34, 60, 80, 0.2);

            &:hover {
                width: 250px;
                background-color: #FFA500;
                border-radius: 8px;
            }
        }
    }
}