.container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);

    .modal_container {
        padding: 15px;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 15px;
        background: white;
        border-radius: 16px;
        backdrop-filter: blur(5px);
        border: 2px solid #ffa60074;
        max-width: 30%;

        @media (max-width: 1000px) {
            max-width: 70%;
        }

        @media (max-width: 700px) {
            max-width: 90%;
        }

        p {
            font-size: 20px;
            color: black;
            font-weight: 600;
            text-align: center;
        }

        .info_container {
            display: flex;
            align-items: center;
            gap: 15px;

            .icon {
                width: 50px;
                height: 50px;
                color: #ffa600;
            }

            p {
                margin: 0;
                font-weight: 400;
                color: gray;
                font-size: 14px;
                text-align: start;

                span {
                    color: black;
                }
            }


        }

        .reasons_container {
            display: grid;
            margin-left: auto;
            margin-right: auto;
            grid-template-columns: 1fr 1fr 1fr;
            width: fit-content;
            column-gap: 15px;

            .item {
                border-radius: 16px;
                background-color: #ffa60074;
                cursor: pointer;
                padding: 5px 10px;
                font-size: 14px;
                transition-duration: 300ms;
                -webkit-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                -moz-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);

                &:hover {
                    background-color: #ffa600;
                }
            
                &.on{
                    background-color: #ffa600;
                }
            }
        }

        .input_container {
            margin: 0 25px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .desc_text {
                font-size: 13px;
                color: gray;
                text-align: start;
                font-weight: 400;

                span {
                    color: red;
                }
            }
        
            .text_area{
                border-color: #ffa600 !important;

                &:hover{
                    border-color: #ffa600 !important;
                }
            }           
        }

        .btn {
            margin-top: 15px;
            width: fit-content;
            height: fit-content;
            margin-left: auto;
            margin-right: auto;
            background-color: #ffa60074;
            border-radius: 16px;
            border: 0;
            font-size: 15px;
            -webkit-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);

            &:hover {
                background-color: #ffa600;
                color: black !important;
                border-color: #ffa600 !important;
            }
        }
    }
}