.container{
    display: flex;
    align-items: center;
    padding-bottom: 50px;

    .test_container{
        display: flex;
        flex-direction: column;
        width: 50%;

        input{
            padding: 10px;
            border: 1px solid red;
            border-radius: 16px;
        }
    }

    .test_value{
        max-width: 50%;
    }

    button{
        cursor: pointer;
        background-color: rgb(255, 0, 0);
        padding: 10px 15px;
        width: fit-content;
        color: white;
    }
}