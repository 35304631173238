.container {
    background-color: rgb(211, 211, 211, 0.5);

    @media (max-width: 710px) {
        display: none;
    }

    .logo {
        background-color: black;
        width: 50px;
        height: 50px;
        margin-top: 25px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 100px;
    }

    .items_container {
        width: 100%;
        height: fit-content;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 15px;
        gap: 25px;

        .item {
            padding: 10px;
            border-radius: 16px;
            margin-left: auto;
            margin-right: auto;
            display: flex;
            gap: 3px;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            transition-duration: 300ms;

            &.active{
                color: black;
                background-color: #FFA500;
                -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
                -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
                box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
            }

            p {
                white-space: nowrap;
                font-size: 11px;
                font-weight: 600;
            }

            &:hover {
                color: black;
                background-color: #FFA500;
                -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
                -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
                box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
            }
        }
    }
}