.container {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);

    .modal_container {
        padding: 15px;
        width: fit-content;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 15px;
        background: white;
        border-radius: 16px;
        backdrop-filter: blur(5px);
        border: 2px solid #ffa60074;

        .close_icon_container {
            position: absolute;
            cursor: pointer;
            right: 5px;
            top: 5px;
        }

        p {
            margin-left: 30px;
            margin-right: 30px;
            font-size: 20px;
            color: black;
            font-weight: 600;
        }

        .track_container {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 5px;

            p {
                margin-left: 0;
                font-size: 17px;
                margin-right: 0;
            }

            .theme_container {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;

                .theme {
                    background-color: #ffa60087;
                    padding: 5px 10px;
                    border-radius: 16px;
                    width: fit-content;
                    font-size: 15px;
                    cursor: pointer;
                    font-weight: 600;
                    -webkit-box-shadow: 4px 4px 8px -6px rgba(34, 60, 80, 0.2);
                    -moz-box-shadow: 4px 4px 8px -6px rgba(34, 60, 80, 0.2);
                    box-shadow: 4px 4px 8px -6px rgba(34, 60, 80, 0.2);
                    transition-duration: 300ms;
                
                    &.on{
                        background-color: #ffa600;
                    }
                
                    &:hover{
                        background-color: #ffa600;
                    }
                }
            }
        }

        .input_container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            p {
                font-size: 17px;
                margin-left: 0;
            }

            input {
                color: black;
                height: fit-content;
                border-radius: 16px;
                font-size: 17px;
                border-width: 2px;

                &:hover {
                    border-color: #FFA500;
                }

                &:focus {
                    border-color: #FFA500;
                }
            }
        
            .example{
                color: lightgray;
                font-size: 12px;
                font-weight: 400;
            }
        }

        button {
            background-color: lightgray;
            height: fit-content;
            border-radius: 16px;
            font-weight: 400;
            font-size: 17px;

            &:hover {
                background-color: #FFA500 !important;
            }
        }
    }
}