.spin{
    color: #ffa600;
    position: absolute;
    top: 50%;
    left: 50%;
}

.container {
    margin-top: 25px;

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 710px) {
            display: flex;
            align-items: normal;
            gap: 15px;
            flex-direction: column;
        }

        .right_container {
            display: flex;
            align-items: center;
            gap: 25px;

            .themes_contianer {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 15px;

                .item {
                    -webkit-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                    -moz-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                    box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                    cursor: pointer;
                    background-color: #ffa600;
                    width: fit-content;
                    color: white;
                    border-radius: 8px;
                    padding: 5px 10px;
                }
            
                @media (max-width: 550px) {
                    gap: 5px;
                }
            }

            .search_track {
                display: flex;
                align-items: center;
                background-color: white;
                padding: 10px;
                border-radius: 16px;
                gap: 10px;
                -webkit-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                -moz-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);

                .icon {
                    padding: 5px;
                    background-color: #b0c4de;
                    border-radius: 8px;
                }

                input {
                    font-weight: 600;
                    font-size: 15px;
                }
            }

            .add_btn {
                display: flex;
                align-items: center;
                font-size: 15px;
                padding: 10px 15px;
                border-radius: 8px;
                font-weight: 600;
                white-space: nowrap;
                gap: 5px;
                background-color: #ffa60094;
                -webkit-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                -moz-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
                cursor: pointer;
                transition-duration: 300ms;

                &:hover {
                    background-color: #ffa600;
                }
            
                @media (max-width: 710px) {
                    margin-bottom: auto;
                    padding: 7px 13px;
                }
            }
        }
    }

    .items_container {
        width: 100%;
        margin-top: 25px;
        display: flex;
        row-gap: 15px;
        column-gap: 25px;
        flex-wrap: wrap;
    }
}