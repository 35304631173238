.container {
    margin-top: 25px;

    .all_blog_sections {
        margin-top: 15px;
        display: grid;
        width: fit-content;
        gap: 15px;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

        .item {
            filter: drop-shadow(0 0 10px #ffa60088);
            background-color: #ffa60088;
            width: fit-content;
            height: fit-content;
            padding: 7px 15px;
            font-weight: 600;
            border-radius: 8px;
            -webkit-box-shadow: 4px 4px 8px -6px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 4px 8px -6px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 4px 8px -6px rgba(34, 60, 80, 0.2);
            cursor: pointer;

        }
    }

    .items {
        display: flex;
        flex-wrap: wrap;
        column-gap: 25px;
        row-gap: 15px;
        margin-top: 15px;

        @media (max-width: 710px) {
            width: min-content;
            margin-left: auto;
            margin-right: auto;
        }
    }
}