.container{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);

    .modal_container{
        padding: 15px;
        width: fit-content;
        height: fit-content;
        max-width: 50%;
        display: flex;
        flex-direction: column;
        gap: 15px;
        background: rgb(241, 241, 241);
        border-radius: 16px;
        backdrop-filter: blur(5px);
        border: 2px solid #ffa60074;
    
        .track {
            background-color: white;
            padding: 15px 25px;
            width: 100%;
            gap: 10px;
            align-items: center;
            border-radius: 16px;
            display: flex;
            -webkit-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
            -moz-box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);
            box-shadow: 4px 4px 8px -4px rgba(34, 60, 80, 0.2);

            .title{
                font-size: 17px;
                font-weight: 600;
            }

            .other {
                font-weight: 400;
                color: lightgray;
            }
        
            .btn_close{
                cursor: pointer;
                position: absolute;
                right: 25px;
                top: 25px;
            }
        }

        .count_video{
            font-weight: 600;
        }
    }
}