.container {
    background-color: white;
    border-radius: 16px;
    padding: 10px 25px;
    height: fit-content;
    max-width: 600px;
    position: relative;
    -webkit-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 8px -7px rgba(34, 60, 80, 0.2);

    .left {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 15px;
        margin-right: 35px;

        @media (max-width: 450px) {
            gap: 10px;
        }

        .img {
            width: 70px;
            height: 70px;
            border-radius: 100%;

            @media (max-width: 450px) {
                width: 40px;
                height: 40px;
            }
        }

        .data {
            width: 100%;

            .header {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;



                .title {
                    font-weight: 600;
                    max-width: 250px;
                    cursor: pointer;
                    font-size: 20px;

                    @media (max-width: 600px) {
                        font-size: 17px;
                    }
                
                    @media (max-width: 450px) {
                        font-size: 15px;
                        white-space: nowrap;
                    }
                }

                .tag {
                    font-weight: 600px;
                    font-size: 15px;
                    color: gray;

                    @media (max-width: 600px) {
                        font-size: 14px;
                    }

                    @media (max-width: 450px) {
                        font-size: 10px;
                    }
                }

                .date {
                    margin-top: 5px;
                    margin-bottom: auto;
                    color: lightgray;
                    font-weight: 600;
                    font-size: 13px;
                
                    @media (max-width: 450px) {
                        font-size: 10px;
                        text-align: end;
                    }
                }
            }

            .other_container {
                margin-top: 10px;

                .static_container {
                    p {
                        font-size: 15px;
                        color: gray;

                        span {
                            color: black;
                            font-weight: 600;
                        }
                    
                        @media (max-width: 450px) {
                            font-size: 13px;
                        }
                    }
                }
            }
        }

        .delete_container {
            position: absolute;
            right: 10px;
            top: 10px;

            .delete_icon {
                cursor: pointer;
                width: 25px;
                height: 25px;
                transition-duration: 300ms;

                &:hover {
                    width: 30px;
                    height: 30px;
                    color: red;
                }
            }
        }
    }

    .flex_container {
        display: flex;
        gap: 15px;
        align-items: center;
        justify-content: space-between;

        .spin{
            color: red;
        }

        .get_paymen_btn {
            font-size: 10px;
            border-radius: 8px;
            border: 1px solid #ffa600aa !important;
            padding: 5px;
            display: flex;
            color: gray;
            height: fit-content;
        
            &:hover{
                background-color: #ffa600aa;
                color: white !important;
            }
        }

        .theme {
            background-color: #ffa600aa;
            width: fit-content;
            padding: 5px 10px;
            font-weight: 600;
            font-size: 14px;
            border-radius: 8px;
        }
    }
}